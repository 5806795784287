{
  let url = document.URL;
  if (url.match(/event[\/]*#\//)) {
    let newPath = url.replace(/(.*)[\/]*#\/(.*)/, '/event/$2')
    newPath = newPath.replaceAll('//', '/')
    location.href = newPath
  } else if (url.match(/#\//)) {
    let newPath = url.replace(/(.*)#\/(.*)/, '/event/$2')
    newPath = newPath.replaceAll('//', '/')
    location.href = newPath
  }
}

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VisitorApp from './EventApp'
import router from './router/indexEvent'
import vuetify from './plugins/vuetify'
import VueCookie from 'vue-cookies'

Vue.config.productionTip = false
Vue.use(VueCookie)
new Vue({
  router,
  vuetify,
  render: h => h(VisitorApp)
}).$mount('#app')
