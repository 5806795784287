 <<template>
  <v-app id="app">
    <v-main>
      <router-view  @loggedin="loadUser"
                    @login="clean"
      />
    </v-main>
  </v-app>
</template>

<script>
import {signout} from './APIUtils.js'
export default {
  name: 'App',
  data() {
    return {
    　items: [
       { title: ' ログアウト '}
      ],
      organization: '',
      name: '',
      organizerId: '',
      loggedin: false
    }
  },
  methods: {
    loadUser(data) {
      this.organization = data.affiliation
      this.name = data.lastName + " " + data.firstName
      this.userId = data.id
      this.loggedin = true;
    },
    clean() {
      this.organization = "",
      this.name = ""
    },
    toPreview(data) {
      this.eventData = data
    },
    logOut() {
      signout()
      .then(res => {
          this.loggedin = false
          this.$router.push({path: '/Signout'})
      })
      .catch(err => {
          console.log("signout catch ", err)
      })
    },
  },
}
</script>

<style>
* {
  /* macOs catalina + Chromeでsans-serifが明朝体になってしまう事象の対応 */
  font-family: 'Avenir', Helvetica, Arial, YuGothic, sans-serif;
}
.headline {
  /* macOs catalina + Chromeでsans-serifが明朝体になってしまう事象の対応 */
  /* vuetify側でimportant指定されていたためimportant指定して上書く */
  font-family: 'Avenir', Helvetica, Arial, YuGothic, sans-serif !important;
}
#app {
  font-family: 'Avenir', Helvetica, Arial, YuGothic, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #555555;
}
.headerlogo{
    width: 200px;
    margin-top: 5px;
}
@media screen and (max-width: 960px) {
      .headerlogo{
        width: 150px;
        margin-top: 5px;
    }
}
</style>
